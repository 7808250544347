import { type TripFilterQueryParams } from '@vakantiesnl/services/src/context/useTripFilterContext';
import { getDateYMD, isBetweenMinMaxDepartureDate } from '@vakantiesnl/services/src/util/dateUtils';
import { convertDurationsToArray } from '@vakantiesnl/services/src/util/durations';
import { unformatPaxQuery } from '@vakantiesnl/services/src/util/partyUtils';
import { type Search } from '@vakantiesnl/types/src';
import dayjs from 'dayjs';
import { cloneDeep, isEqual } from 'lodash';

import { type TripFiltersState } from './interfaces';
import { initialState as importedInitialState } from './reducer';
import { getHistoryState } from '../../browser-history';

/**
 * Checks if the given date string falls within a valid date range and returns a formatted date or undefined.
 *
 * @param query An object containing a departureDate string.
 * @returns A string representing the formatted date or undefined if invalid or out of range.
 */
export function getInitialDepartureDate(departureDate?: string): string[] | null {
	if (departureDate) {
		const date = dayjs(departureDate.split(/[\+\/]/)[0]);

		// Check if the query is valid
		if (date.isValid() && isBetweenMinMaxDepartureDate(date.toDate())) {
			return [getDateYMD(date.toDate())];
		}
	}
	return null;
}

/**
 * Determines the initial party composition based on a given pax query string and context party.
 * The function attempts to unformat the pax query into a Party object. If the unformatted pax query
 * is defined and not equal to the context party (based on a deep equality check), the unformatted
 * pax query is used as the return value. Otherwise, the context party is returned.
 *
 * @param {string | undefined} queryParty - The pax query string representing the party composition, or undefined.
 * @param {Search.PartyComposition[]} contextParty - The current context party composition.
 * @returns {Search.PartyComposition[]} The initial party composition based on the provided pax query and context party.
 */
export function getInitialPartyComposition(
	queryParty: string | undefined,
	contextParty: Search.PartyComposition[],
): Search.PartyComposition[] {
	const unformattedPaxQuery = queryParty ? unformatPaxQuery(queryParty) : null;
	const arePartiesEqual = isEqual(unformattedPaxQuery, contextParty);

	// The initial party is equal to the query queryParty or to the context party
	const party = unformattedPaxQuery && !arePartiesEqual ? unformattedPaxQuery : contextParty;
	return party;
}

export function getTripFilterStateFromParams(tripFilterParams: TripFilterQueryParams): TripFiltersState {
	const initialState = cloneDeep(importedInitialState);
	const historyState = getHistoryState<TripFiltersState>('tripFilterContext');

	if (historyState) {
		return { ...historyState };
	}

	if (!!tripFilterParams.airports?.length) {
		initialState.airports = tripFilterParams.airports;
	}

	initialState.departureDate = getInitialDepartureDate(tripFilterParams.departureDate);

	if (!!tripFilterParams.overrideDurations) {
		initialState.durationsOverride = convertDurationsToArray(tripFilterParams.overrideDurations);
	}

	if (!!tripFilterParams.mealplans?.length) {
		initialState.mealplans = tripFilterParams.mealplans;
	}

	initialState.minPrice = tripFilterParams.minPrice;
	initialState.maxPrice = tripFilterParams.maxPrice;

	initialState.airlines = tripFilterParams.airlines;
	initialState.flightProvider = tripFilterParams.flightProvider;
	initialState.tourOperators = tripFilterParams.tourOperators;

	const selectedDateDayjs = dayjs(tripFilterParams.selected.date);
	const isSelectedDateValid = selectedDateDayjs.isValid() && isBetweenMinMaxDepartureDate(selectedDateDayjs.toDate());

	initialState.selected = {
		...tripFilterParams.selected,
		date: isSelectedDateValid ? tripFilterParams.selected.date : undefined,
	};

	return initialState;
}
