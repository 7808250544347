import {
	RESET_TRIP_FILTER,
	SET_INITIAL_STATE,
	UPDATE_AIRPORTS_TRIP_FILTER,
	UPDATE_DEPARTURE_DATE_TRIP_FILTER,
	UPDATE_DURATION_RANGE_TRIP_FILTER,
	UPDATE_MEALPAN_TRIP_FILTER,
	UPDATE_SELECTED_TRIP,
	UPDATE_SELECTED_FLIGHT,
	UPDATE_SELECTED_ROOM_TYPE,
	UPDATE_SELECTED_MEAL_PLAN,
	UPDATE_INVALID_PRESELECTION,
	UPDATE_IS_INITIAL_LOAD,
	UPDATE_SELECTED_DIRECT_ONLY,
} from './constants';
import { type TripFiltersState } from './interfaces';

type UpdateAirportsTripFilter = {
	type: typeof UPDATE_AIRPORTS_TRIP_FILTER;
	payload: string[];
};

type UpdateDepartureDateTripFilter = {
	type: typeof UPDATE_DEPARTURE_DATE_TRIP_FILTER;
	payload: string[];
};

type UpdateDurationRangeTripFilter = {
	type: typeof UPDATE_DURATION_RANGE_TRIP_FILTER;
};

type UpdateMealplanTripFilter = {
	type: typeof UPDATE_MEALPAN_TRIP_FILTER;
	payload: string[];
};

type UpdateInvalidPreselection = {
	type: typeof UPDATE_INVALID_PRESELECTION;
	payload: boolean;
};

type UpdateSelectedTrip = {
	type: typeof UPDATE_SELECTED_TRIP;
	payload: TripFiltersState['selected']['date'];
};

type UpdateSelectedDirectOnly = {
	type: typeof UPDATE_SELECTED_DIRECT_ONLY;
	payload: TripFiltersState['selected']['directOnly'];
};

type UpdateSelectedFlight = {
	type: typeof UPDATE_SELECTED_FLIGHT;
	payload: TripFiltersState['selected']['flight'];
};

type UpdateSelectedRoomType = {
	type: typeof UPDATE_SELECTED_ROOM_TYPE;
	payload: TripFiltersState['selected']['roomType'];
};

type UpdateSelectedMealPlan = {
	type: typeof UPDATE_SELECTED_MEAL_PLAN;
	payload: TripFiltersState['selected']['mealPlan'];
};

type UpdateIsInitialLoad = {
	type: typeof UPDATE_IS_INITIAL_LOAD;
	payload: boolean;
};

type ResetTripFilters = {
	type: typeof RESET_TRIP_FILTER;
	payload: boolean;
};

type SetInitialStateFilters = {
	type: typeof SET_INITIAL_STATE;
	payload: TripFiltersState;
};

export const updateAirportsTripFilterAction = (payload: string[]): UpdateAirportsTripFilter => ({
	type: UPDATE_AIRPORTS_TRIP_FILTER,
	payload,
});

export const updateDepartureDateTripFilterAction = (payload: string[]): UpdateDepartureDateTripFilter => ({
	type: UPDATE_DEPARTURE_DATE_TRIP_FILTER,
	payload,
});

export const updateDurationRangeTripFilterAction = (): UpdateDurationRangeTripFilter => ({
	type: UPDATE_DURATION_RANGE_TRIP_FILTER,
});

export const updateMealplanTripFilterAction = (payload: string[]): UpdateMealplanTripFilter => ({
	type: UPDATE_MEALPAN_TRIP_FILTER,
	payload,
});

export const updateInvalidPreselectionAction = (payload: boolean): UpdateInvalidPreselection => ({
	type: UPDATE_INVALID_PRESELECTION,
	payload,
});

export const updateSelectedTripAction = (payload: TripFiltersState['selected']['date']): UpdateSelectedTrip => ({
	type: UPDATE_SELECTED_TRIP,
	payload,
});

export const updateSelectedDirectOnlyAction = (
	payload: TripFiltersState['selected']['directOnly'],
): UpdateSelectedDirectOnly => ({
	type: UPDATE_SELECTED_DIRECT_ONLY,
	payload,
});

export const updateSelectedFlightAction = (payload: TripFiltersState['selected']['flight']): UpdateSelectedFlight => ({
	type: UPDATE_SELECTED_FLIGHT,
	payload,
});

export const updateSelectedRoomTypeAction = (
	payload: TripFiltersState['selected']['roomType'],
): UpdateSelectedRoomType => ({
	type: UPDATE_SELECTED_ROOM_TYPE,
	payload,
});

export const updateSelectedMealPlanAction = (
	payload: TripFiltersState['selected']['mealPlan'],
): UpdateSelectedMealPlan => ({
	type: UPDATE_SELECTED_MEAL_PLAN,
	payload,
});

export const updateIsInitialLoadAction = (payload: boolean): UpdateIsInitialLoad => ({
	type: UPDATE_IS_INITIAL_LOAD,
	payload,
});

export const resetTripFilterAction = (isShortStayAcco: boolean): ResetTripFilters => ({
	type: RESET_TRIP_FILTER,
	payload: isShortStayAcco,
});

export const setInitialStateAction = (state: TripFiltersState): SetInitialStateFilters => ({
	type: SET_INITIAL_STATE,
	payload: state,
});

export type ActionTypes =
	| UpdateAirportsTripFilter
	| UpdateDepartureDateTripFilter
	| UpdateDurationRangeTripFilter
	| UpdateMealplanTripFilter
	| UpdateInvalidPreselection
	| UpdateSelectedTrip
	| UpdateSelectedDirectOnly
	| UpdateSelectedFlight
	| UpdateSelectedRoomType
	| UpdateSelectedMealPlan
	| UpdateIsInitialLoad
	| ResetTripFilters
	| SetInitialStateFilters;
