import { useMemo } from 'react';

import type { TripFiltersState } from '@vakantiesnl/services/src/hooks/rest/trip-filters/interfaces';
import { type SearchUrl } from '@vakantiesnl/services/src/hooks/useSearchUrlBuilder';
import { isBetweenMinMaxDepartureDate } from '@vakantiesnl/services/src/util/dateUtils';
import { DEFAULT_PARTY, formatPaxQuery, unformatPaxQuery } from '@vakantiesnl/services/src/util/partyUtils';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { type NextRouter } from 'next/router';

import { type TripFilterQueryParams } from '../context/useTripFilterContext';
import { useGlobalFiltersStore } from '../stores';
import { DEFAULT_DURATIONS, DEFAULT_DURATIONS_ARRAY, parseQueryToValidDurations } from '../util/durations';
import { getAllParamsExceptRouteParams } from '../util/queryHelpers';

type AccommodationFilters = {
	page?: number;
} & TripFiltersState;

const defaultDurations = [DEFAULT_DURATIONS, DEFAULT_DURATIONS_ARRAY.join('+')];

const cleanUpParams = (queryParams: URLSearchParams): void => {
	queryParams.forEach((value, key) => {
		if (!value || value.length === 0) queryParams.delete(key);
	});

	if (queryParams.get('pax') && !unformatPaxQuery(queryParams.get('pax') as string)) {
		queryParams.delete('pax');
	}

	/**
	 * Remove durations query when:
	 * the durations is equal to
	 * - the default durations `6-13` or `6+7+8+9+10+11+12+13` for every lister except city trip
	 * - the default durations `2-5` or `2+3+4+5` for city trip campaign
	 */
	const overrideDurationsParam = queryParams.get('o_durations');
	if (overrideDurationsParam) {
		if (defaultDurations.includes(overrideDurationsParam)) {
			queryParams.delete('o_durations');
		}
	}

	const durationsParam = queryParams.get('durations');
	if (durationsParam) {
		if (defaultDurations.includes(durationsParam)) {
			queryParams.delete('durations');
		}
	}
};

function parseHashToQueryObject(hash: string): Record<string, string> {
	const hashAsParams = new URLSearchParams(hash.replace('#', ''));
	const query: Record<string, string> = {};

	for (const [key, value] of hashAsParams.entries()) {
		query[key] = value;
	}

	return query;
}

export function getParamsFromHash(hash: string, isOwnTransport: boolean): TripFilterQueryParams {
	const query = parseHashToQueryObject(hash);

	const params: TripFilterQueryParams = {
		selected: {},
	};

	if (query['departure_date']) {
		params.departureDate = query['departure_date'];
	}

	if (query['tour_operators']) {
		params.tourOperators = query['tour_operators'].split('+');
	}

	if (query['pax']) {
		params.pax = query['pax'];
	}

	const durations = parseQueryToValidDurations(query['durations']);

	if (durations) {
		params.durations = durations;
	}

	if (query['s_date']) {
		params.selected.date = query['s_date'];
	}

	if (query['s_roomtype']) {
		params.selected.roomType = query['s_roomtype'];
	}

	const overrideDurations = parseQueryToValidDurations(query['o_durations']);

	if (overrideDurations) {
		params.overrideDurations = overrideDurations;
	}

	if (!isOwnTransport) {
		params.selected.directOnly = !!query['s_direct_only'];

		if (query['flight_provider']) {
			params.flightProvider = query['flight_provider'];
		}

		if (query['airlines']) {
			params.airlines = query['airlines'].split('+');
		}

		if (query['airports']) {
			params.airports = query['airports'].split('+');
		}

		if (query['mealplans']) {
			params.mealplans = query['mealplans'].split('+');
		}

		if (query['min_price']) {
			params.minPrice = query['min_price'];
		}

		if (query['max_price']) {
			params.maxPrice = query['max_price'];
		}

		if (query['s_mealplan']) {
			params.selected.mealPlan = query['s_mealplan'];
		}

		if (query['s_outbound_flight'] && query['s_inbound_flight']) {
			params.selected.flight = {
				inbound: query['s_inbound_flight'],
				outbound: query['s_outbound_flight'],
			};
		}
	}

	return params;
}

export const useAccommodationUrlBuilder = (
	router: NextRouter,
	filters: Partial<AccommodationFilters>,
): SearchUrl | undefined => {
	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	return useMemo(() => {
		const { asPath, query } = router;
		const clearedQuery = getAllParamsExceptRouteParams(query);

		const asPathWithoutQuery = asPath.split(/\?|#|%/)[0];

		/** Filter queries that start with UTM from the URL  */
		const hashQueryParams = new URLSearchParams();

		if (filters?.airports && filters?.airports?.length > 0) {
			hashQueryParams.set('airports', filters.airports.join('+'));
		}

		if (filters?.departureDate) {
			const date = dayjs(filters?.departureDate[0]);

			if (date.isValid() && isBetweenMinMaxDepartureDate(date.toDate())) {
				hashQueryParams.set('departure_date', filters.departureDate.join('+'));
			} else hashQueryParams.delete('departure_date');
		}

		if (!!filters?.durationsOverride?.length) {
			hashQueryParams.set('o_durations', filters.durationsOverride.join('+'));
		} else {
			hashQueryParams.delete('o_durations');
		}

		if (globalFilters.durations) {
			hashQueryParams.set(
				'durations',
				Array.isArray(globalFilters.durations) ? globalFilters.durations.join('+') : globalFilters.durations,
			);
		}

		if (filters?.mealplans && filters?.mealplans?.length > 0) {
			hashQueryParams.set('mealplans', filters?.mealplans.join('+'));
		}

		/** Set the pax in the url when the filter party is not equal to the default */
		if (globalFilters.party && !isEqual(globalFilters.party, DEFAULT_PARTY)) {
			hashQueryParams.set('pax', formatPaxQuery(globalFilters.party));
		} else {
			hashQueryParams.delete('pax');
		}

		if (filters?.selected?.date) {
			hashQueryParams.set('s_date', filters.selected.date);
		} else hashQueryParams.delete('s_date');

		if (filters?.selected?.directOnly) {
			hashQueryParams.set('s_direct_only', String(filters.selected.directOnly));
		} else hashQueryParams.delete('s_direct_only');

		if (filters?.selected?.flight?.outbound && filters.selected.flight.inbound) {
			hashQueryParams.set('s_outbound_flight', filters.selected.flight.outbound);
			hashQueryParams.set('s_inbound_flight', filters.selected.flight.inbound);
		} else {
			hashQueryParams.delete('s_outbound_flight');
			hashQueryParams.delete('s_inbound_flight');
		}

		if (filters?.selected?.roomType) {
			hashQueryParams.set('s_roomtype', filters.selected.roomType);
		} else hashQueryParams.delete('s_roomtype');

		if (filters?.selected?.mealPlan) {
			hashQueryParams.set('s_mealplan', filters.selected.mealPlan);
		} else hashQueryParams.delete('s_mealplan');

		if (filters?.minPrice) {
			hashQueryParams.set('min_price', filters.minPrice);
		}

		if (filters?.maxPrice) {
			hashQueryParams.set('max_price', filters.maxPrice);
		}

		cleanUpParams(hashQueryParams);

		return {
			href: {
				pathname: '/accommodation',
				query,
			},
			as: {
				pathname: `${asPathWithoutQuery}`,
				query: clearedQuery.toString(),
			},
			hash: getHashUrl(hashQueryParams),
		};
	}, [filters, router, globalFilters]);
};

function getHashUrl(params: URLSearchParams): string {
	return params.size > 0 ? `#${params.toString()}` : '';
}
