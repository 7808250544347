import { type ActionTypes } from './actions';
import {
	UPDATE_AIRPORTS_TRIP_FILTER,
	UPDATE_DEPARTURE_DATE_TRIP_FILTER,
	UPDATE_DURATION_RANGE_TRIP_FILTER,
	UPDATE_MEALPAN_TRIP_FILTER,
	UPDATE_INVALID_PRESELECTION,
	UPDATE_SELECTED_TRIP,
	UPDATE_SELECTED_FLIGHT,
	UPDATE_SELECTED_ROOM_TYPE,
	UPDATE_SELECTED_MEAL_PLAN,
	UPDATE_IS_INITIAL_LOAD,
	RESET_TRIP_FILTER,
	UPDATE_SELECTED_DIRECT_ONLY,
	SET_INITIAL_STATE,
} from './constants';
import { type TripFiltersState } from './interfaces';

export const initialState: TripFiltersState = {
	airports: [],
	departureDate: null,
	durationsOverride: null,
	mealplans: [],
	invalidPreselection: false,
	selected: {},
	isInitialLoad: true,
	hasLoadedInitialState: false,
};

export const reducer = (state: TripFiltersState = initialState, data: ActionTypes): TripFiltersState => {
	switch (data.type) {
		case UPDATE_AIRPORTS_TRIP_FILTER:
			return { ...state, airports: data.payload };
		case UPDATE_DEPARTURE_DATE_TRIP_FILTER:
			return { ...state, departureDate: data.payload };
		case UPDATE_DURATION_RANGE_TRIP_FILTER:
			return {
				...state,
				// Reset the duration override when a manual duration has been chosen
				durationsOverride: null,
			};
		case UPDATE_MEALPAN_TRIP_FILTER:
			return { ...state, mealplans: data.payload };
		case UPDATE_INVALID_PRESELECTION:
			return { ...state, invalidPreselection: data.payload };
		case UPDATE_SELECTED_TRIP:
			return { ...state, selected: { ...state.selected, date: data.payload } };
		case UPDATE_SELECTED_DIRECT_ONLY:
			return { ...state, selected: { ...state.selected, directOnly: data.payload } };
		case UPDATE_SELECTED_FLIGHT:
			return { ...state, selected: { ...state.selected, flight: data.payload } };
		case UPDATE_SELECTED_ROOM_TYPE:
			return { ...state, selected: { ...state.selected, roomType: data.payload } };
		case UPDATE_SELECTED_MEAL_PLAN:
			return { ...state, selected: { ...state.selected, mealPlan: data.payload } };
		case UPDATE_IS_INITIAL_LOAD:
			return { ...state, isInitialLoad: data.payload };
		case RESET_TRIP_FILTER:
			return {
				...initialState,
			};
		case SET_INITIAL_STATE:
			return {
				...data.payload,
				hasLoadedInitialState: true,
			};
		default:
			return state;
	}
};
